import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import "./App.css";

import ScrollToPosition from "./components/ScrollToPosition";
import TopMenu from "./components/TopMenu";
import Gallery from "./components/Gallery";

import UserModel from "./models/UserModel";

import config from "./lib/config";
import scroll from "./lib/scroll";
import token from "./lib/token";

const Footer = styled.div`
  width: 100%;
  text-align: right;
  font-size: x-small;
  margin: 0;
  padding: 0;
  color: var(--inactive-color);
`;

const registerCountryData = (lang) => {
  const countryData = require("i18n-iso-countries");
  try {
    countryData.registerLocale(
      require("i18n-iso-countries/langs/" + lang + ".json")
    );
  } catch (err) {
    // Fall back to English
    countryData.registerLocale(require("i18n-iso-countries/langs/en.json"));
  }
  return countryData;
};

const App = () => {
  const [user, setUser] = React.useState(undefined);
  const [lang, setLang] = React.useState(config.DEFAULT_LANGUAGE);
  const [countryData, setCountryData] = React.useState(
    registerCountryData(lang)
  );

  const scrollState = scroll();

  const { i18n } = useTranslation();
  i18n.on("languageChanged", (lang) => {
    setLang(lang);
    setCountryData(registerCountryData(lang));
  });
  const storedLang = window.localStorage.getItem("lang");
  if (storedLang && storedLang !== lang) {
    i18n.changeLanguage(storedLang);
  } else if (lang !== i18n.language) {
    i18n.changeLanguage(lang);
  }

  if (!user) {
    const storedUserJson = window.localStorage.getItem("user");
    if (storedUserJson) {
      const storedUser = UserModel(JSON.parse(storedUserJson));
      token.setToken(storedUser.token());
      setUser(storedUser);
    }
  }

  return (
    <>
      <Helmet>
        <title>Photo diary</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <TopMenu user={user} setUser={setUser} lang={lang} />
      <Router>
        <ScrollToPosition scrollState={scrollState}>
          <Switch>
            <Route path="/g/:galleryId/stats">
              <Gallery
                user={user}
                lang={lang}
                countryData={countryData}
                isStats={true}
                scrollState={scrollState}
              />
            </Route>
            <Route path="/g/:galleryId/:year/:month/:day/:photoId">
              <Gallery
                user={user}
                lang={lang}
                countryData={countryData}
                scrollState={scrollState}
              />
            </Route>
            <Route path="/g/:galleryId/:year?/:month?/:day?">
              <Gallery
                user={user}
                lang={lang}
                countryData={countryData}
                scrollState={scrollState}
              />
            </Route>
            <Route path="/g">
              <Gallery
                user={user}
                lang={lang}
                countryData={countryData}
                scrollState={scrollState}
              />
            </Route>
            <Route path="/">
              <Redirect to="/g" />
            </Route>
          </Switch>
        </ScrollToPosition>
        <Footer>
          Generated by{" "}
          <a href="https://github.com/vlumi/photo-diary">Photo Diary</a> © Ville
          Misaki
        </Footer>
      </Router>
    </>
  );
};
export default App;
